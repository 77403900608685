import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Col, Row } from 'react-styled-flexboxgrid'
import enGB from 'date-fns/locale/en-GB'
import {
  ArrayHelpers,
  // FormikActions,
  Field,
  FieldArray,
  FieldProps,
  Formik,
  FormikErrors,
  FormikProps,
} from 'formik'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import moment from 'moment'
import Button from '@berlitz/button'
import Spacer from '@berlitz/spacer'

import Datepicker from '@components/Datepicker'
import Checkbox from '@components/Checkbox'
import Radio from '@components/Radio'
import Select from '@components/Select'
import { RepeatOnOptions } from './constants'
import { FormValueProps, FormErrorProps, SelectProp } from './interface'
import { Footer, Label, CustomPicker, FormCol, ErrorMsg } from './style'
import { WarningTriangle } from '@berlitz/icons'
import { TEventResource } from '@components/InstructorScheduler/interface'
import PortalInstructor from '@classes/Instructor'
import PortalAvailabilityForm from '@classes/AvailabilityForm'
import { DATE_FORMAT, getKeepLessonsStorageKey } from '@utils/constants'
import useTimeFormat from '@hooks/useTimeFormat'

export interface FormProps {
  initialValues?: FormValueProps
  onClose: Function
  onDelete?: Function
  onSave: (values: FormValueProps) => void
  onChange?: (values: FormValueProps) => void
  isEdit?: boolean
  isTimeOff?: boolean
  readOnly?: boolean
  isFlexOnly?: boolean
  AvailabilityForm: PortalAvailabilityForm
  resource: TEventResource
  Instructor: PortalInstructor
  isAdmin?: boolean
}

const AvailabilityForm: React.FC<FormProps> = ({
  initialValues,
  onClose,
  onSave,
  isEdit,
  isTimeOff,
  readOnly,
  isFlexOnly,
  AvailabilityForm,
  resource,
  Instructor,
  isAdmin,
}) => {
  const intl = useIntl()
  const { getTimePickerFormat, isTwelveHour, isTimeFormatDisabled } = useTimeFormat()
  const [startDate, setStartDate] = useState<string | undefined>()
  const [endDate, setEndDate] = useState<string | undefined>()

  const lessonTypeOptions = AvailabilityForm.LessonTypeOptions
  const availabilityTypeOptions = AvailabilityForm.TypeOptions
  const learningcenters: SelectProp[] = AvailabilityForm.LearningCenterOptions

  const form = useRef<FormikProps<FormValueProps>>(null)

  useEffect(() => {
    if (form.current) {
      form.current?.validateForm()

      if (lessonTypeOptions.length === 1) {
        form.current?.setFieldValue('LessonType', lessonTypeOptions[0].value)
      }

      if (isTimeOff) {
        form.current?.setFieldValue('RepeatOn', RepeatOnOptions.map(({ value }) => value).sort())

        if (!isEdit) {
          form.current?.setFieldValue(
            'KeepLessons',
            localStorage.getItem(getKeepLessonsStorageKey(Instructor?.Id)) === 'true'
          )
        }
      }
    }

    setStartDate(initialValues?.StartDate?.clone().format(DATE_FORMAT.DATEPICKER))
    setEndDate(initialValues?.EndDate?.clone().format(DATE_FORMAT.DATEPICKER))
  }, [initialValues, isTimeOff])

  const changeDate = useCallback((cb) => cb(), [])
  const debouceRequest = useCallback((cb) => changeDate(cb), [])
  const isInvalidDates = () => {
    const mStart = moment(startDate, DATE_FORMAT.DATEPICKER, true)
    const mEnd = moment(endDate, DATE_FORMAT.DATEPICKER, true)
    return (
      !mStart.isValid() || !mEnd.isValid() || mStart.isAfter(mEnd) || mStart.isBefore(Instructor.CurrentDate, 'day')
    )
  }

  if (!initialValues) {
    return null
  }

  return (
    <Formik
      innerRef={form}
      initialValues={initialValues as FormValueProps}
      isInitialValid={!isTimeOff && lessonTypeOptions.length === 1}
      enableReinitialize
      onSubmit={(values) => {
        onSave(values)
      }}
      validate={(values: FormValueProps) => {
        const errors: FormikErrors<FormErrorProps> = {}

        if (!values.LearningCenterId) {
          errors.LearningCenterId = 'Required'
        }

        // Removed for now
        // if (!values.LessonType.length && !isTimeOff && values.LessonType.length === 2) {
        //   lessonTypeOptions.map((_type, index) => {
        //     errors[`LessonType.${index}`] = 'Required'
        //   })
        // }

        if (!values.LessonType && !isTimeOff) {
          errors.LessonType = 'Required'
        }

        if (!values.RepeatOn.length && !isTimeOff) {
          RepeatOnOptions.map((_date, index) => {
            errors[`RepeatOn.${index}`] = 'Required'
          })
        }

        if (!values.UnAvailabilityType && isTimeOff) {
          errors.UnAvailabilityType = 'Required'
        }
        // react-datepicker has issue where dates aren't retained if dateformat is just time format
        // so we need to compare just the time and select a common date
        const sDate = moment(values.StartTime.format('hh:mm a'), 'hh:mm a')
        const eDate = moment(values.EndTime.format('hh:mm a'), 'hh:mm a')
        if (!sDate.isBefore(eDate)) {
          errors.StartTime = 'Start time must be before end time'
        }

        if (!sDate.isValid()) {
          errors.StartTime = 'Invalid Start Time'
        }

        if (!eDate.isValid()) {
          errors.EndTime = 'Invalid End Time'
        }

        return errors
      }}
    >
      {({ dirty, errors, values, isValid, handleSubmit }: FormikProps<FormValueProps>) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={8} lg={4}>
              <Row>
                <FormCol xs={6} lg={6} hasError={Boolean(errors.StartTime)}>
                  <Field
                    key={values.AllDay} // rerender when allDay is toggled so view can reset to the actual form value
                    name="StartTime"
                    render={({ form, field }) => (
                      <>
                        <Datepicker
                          label={intl.formatMessage({ id: 'Start time' })}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeFormat={getTimePickerFormat()}
                          dateFormat={getTimePickerFormat()}
                          timeCaption="Time"
                          placeholderText="00:00"
                          selected={values.StartTime.clone().toDate()}
                          minTime={values.StartTime.clone().startOf('day').toDate()}
                          maxTime={values.EndTime.clone().subtract(15, 'minutes').toDate()}
                          onChange={(val) => {
                            const m = moment(val)
                            form.setFieldValue(field.name, m)
                          }}
                          disabled={readOnly || values.AllDay}
                          isClearable={false}
                        />
                      </>
                    )}
                  />
                </FormCol>
                <CustomPicker
                  xs={6}
                  lg={6}
                  hasError={Boolean(errors.EndTime)}
                  is12HourFormat={!isTimeFormatDisabled && isTwelveHour}
                >
                  <Field
                    key={values.AllDay} // rerender when allDay is toggled so view can reset to the actual form value
                    name="EndTime"
                    render={({ form, field }) => (
                      <Datepicker
                        label={intl.formatMessage({ id: 'End time' })}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeFormat={getTimePickerFormat()}
                        dateFormat={getTimePickerFormat()}
                        timeCaption="Time"
                        placeholderText="00:00"
                        selected={values.EndTime.clone().toDate()}
                        minTime={values.StartTime.clone().add(15, 'minutes').toDate()}
                        maxTime={values.StartTime.clone().endOf('day').toDate()}
                        injectTimes={[values.EndTime.clone().endOf('day').toDate()]}
                        onChange={(val) => {
                          const m = moment(val)
                          form.setFieldValue(field.name, m)
                        }}
                        disabled={readOnly || values.AllDay}
                        isClearable={false}
                        isEndDateFix={true}
                      />
                    )}
                  />
                </CustomPicker>
              </Row>
              <Row>
                <Col xs={12}>
                  {errors.StartTime ? (
                    <ErrorMsg>
                      <WarningTriangle className="alert-icon" size="xs" color={'error'} />
                      <span>
                        <FormattedMessage id={String(errors.StartTime)} />
                      </span>
                    </ErrorMsg>
                  ) : null}
                </Col>
              </Row>
            </Col>
            <Col xs={4} lg={2}>
              <Col xs={12} style={{ padding: '0' }}>
                <Spacer size="lg" />
                <Field
                  name="AllDay"
                  render={({ form, field }) => (
                    <Checkbox
                      {...field}
                      checked={values.AllDay}
                      value={`${values.AllDay}`}
                      label={intl.formatMessage({ id: 'All day' })}
                      field={{
                        name: intl.formatMessage({ id: 'All day' }),
                      }}
                      onChange={() => {
                        if (!readOnly) {
                          form.setFieldValue('StartTime', initialValues.StartTime.clone())
                          form.setFieldValue('EndTime', initialValues.EndTime.clone())
                          form.setFieldValue(field.name, !values.AllDay)
                        }
                      }}
                    />
                  )}
                />
              </Col>
            </Col>

            {!isTimeOff ? (
              <>
                <Col xs={6} lg={3}>
                  <Label>
                    <FormattedMessage id="Lesson type" />
                  </Label>
                  {lessonTypeOptions.length === 1 ? (
                    <div>
                      <FormattedMessage id="Online" />
                    </div>
                  ) : (
                    <Field
                      name="LessonType"
                      render={({ field }: FieldProps<FormValueProps>) => (
                        <Radio.Group {...field}>
                          {lessonTypeOptions.map((type, index) => (
                            <Radio.Radio
                              key={`lesson-type-${index}`}
                              id={`lesson-type-${index}`}
                              value={type.value}
                              disabled={!isAdmin && !isEmpty(resource) && type.value !== resource?.CalendarTitle}
                            >
                              <FormattedMessage id={type.id} />
                            </Radio.Radio>
                          ))}
                        </Radio.Group>
                      )}
                    />
                  )}
                  {/* For Japan and other coutries. Will be implemented if we get there
                  <FieldArray
                    name="LessonType"
                    validateOnChange
                    render={(arrayHelpers: ArrayHelpers) => (
                      <>
                        <Label>
                          <FormattedMessage id="Lesson type" />
                        </Label>

                        {lessonTypeOptions.map((type, index) => (
                          <React.Fragment key={`lesson-type-${index}`}>
                            <Checkbox
                              checked={values.LessonType.includes(type.value)}
                              name={`LessonType.${index}`}
                              label={type.label}
                              value={`${type.value}`}
                              field={{
                                name: type.label,
                              }}
                              onChange={(e: any) => {
                                if (!readOnly) {
                                  const { insert, remove } = arrayHelpers
                                  if (e.target.checked) {
                                    insert(index, type.value)
                                  } else {
                                    const valueIndex = values.LessonType.indexOf(type.value)
                                    remove(valueIndex)
                                  }
                                }
                              }}
                            />
                            <Spacer size="xxs" />
                          </React.Fragment>
                        ))}
                      </>
                    )}
                  /> */}
                </Col>
                <Col xs={6} lg={3}>
                  <Field
                    name="AvailabilityType"
                    render={({ form, field }: FieldProps<FormValueProps>) => (
                      <>
                        <Label>
                          <FormattedMessage id="Availability type" />
                        </Label>
                        <div id="AvailabilityType">
                          <Select
                            {...field}
                            label=""
                            options={availabilityTypeOptions}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value)
                            }}
                            getPopupContainer={() => document.getElementById('AvailabilityType')}
                            disableMargin
                            disabled={(isAdmin && isEdit) || (!isAdmin && (readOnly || isFlexOnly))}
                          />
                        </div>
                      </>
                    )}
                  />
                </Col>
              </>
            ) : (
              <>
                <Col xs={6} lg={3}>
                  <Field
                    name="UnAvailabilityType"
                    render={({ form, field }: FieldProps<FormValueProps>) => (
                      <>
                        <Label>
                          <FormattedMessage id="Type" />
                        </Label>
                        <div id="UnAvailabilityType">
                          <Select
                            {...field}
                            label=""
                            options={AvailabilityForm.UnAvailabilityTypeOptions || []}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value)
                            }}
                            getPopupContainer={() => document.getElementById('UnAvailabilityType')}
                            disableMargin
                            disabled={readOnly}
                            required
                          />
                        </div>
                      </>
                    )}
                  />
                </Col>
                <Col xs={6} lg={3}>
                  <Spacer size="lg" />
                  <Field
                    name="KeepLessons"
                    render={({ form, field }: FieldProps<FormValueProps>) => (
                      <Checkbox
                        {...field}
                        checked={values.KeepLessons}
                        value={`${values.KeepLessons}`}
                        label={intl.formatMessage({ id: 'Keep lessons' })}
                        field={{
                          name: intl.formatMessage({ id: 'Keep lessons' }),
                        }}
                        onChange={() => {
                          if (!readOnly) {
                            const keepyLessons = !values.KeepLessons
                            form.setFieldValue(field.name, keepyLessons)

                            if (!isEdit) {
                              localStorage.setItem(getKeepLessonsStorageKey(Instructor?.Id), `${keepyLessons}`)
                            }
                          }
                        }}
                      />
                    )}
                  />
                </Col>
              </>
            )}
          </Row>

          <Spacer size="xs" />

          <Row>
            <Col xs={6} lg={3}>
              <Field
                name="LearningCenterId"
                render={({ form, field }: FieldProps<FormValueProps>) => (
                  <>
                    <Label>
                      <FormattedMessage id="Learning center" />
                    </Label>
                    <div id="LearningCenterId">
                      <Select
                        {...field}
                        label=""
                        placeholderText={values.LearningCenterName}
                        options={learningcenters}
                        onChange={(value) => {
                          const selectedLC = learningcenters.find(({ id }) => id === value)
                          form.setFieldValue(field.name, value)
                          form.setFieldValue('LearningCenterName', get(selectedLC, 'label', ''))
                        }}
                        getPopupContainer={() => document.getElementById('LearningCenterId')}
                        disableMargin
                        disabled
                        required
                      />
                    </div>
                  </>
                )}
              />
            </Col>
            <Col xs={6} lg={3}>
              <Field
                name="StartDate"
                render={({ form, field }: FieldProps<FormValueProps>) => (
                  <Datepicker
                    locale={enGB}
                    value={startDate}
                    selected={values.StartDate.clone().toDate()}
                    dateFormat="dd/MM/yyyy"
                    maxDate={values.EndDate.toDate()}
                    label={intl.formatMessage({ id: 'Start date' })}
                    openToDate={values.StartDate.toDate()}
                    onSelect={(val, event) => {
                      const d = moment(val)

                      if (val && d.isValid() && event.type === 'click') {
                        form.setFieldValue(field.name, d)
                        setStartDate(d.format(DATE_FORMAT.DATEPICKER))
                      } else {
                        form.setFieldError(field.name, 'Required')
                      }
                    }}
                    onChangeRaw={(e) => {
                      debouceRequest(() => {
                        setStartDate(e.currentTarget.value)

                        if (AvailabilityForm.isDateValid(e.currentTarget.value)) {
                          const d = moment(e.currentTarget.value, DATE_FORMAT.DATEPICKER)
                          form.setFieldValue(field.name, d)
                          setStartDate(d.format(DATE_FORMAT.DATEPICKER))
                        } else {
                          form.setFieldError(field.name, 'Required')
                        }
                      })
                    }}
                    isClearable={false}
                    disabled={readOnly}
                    popperModifiers={{
                      preventOverflow: {
                        enabled: true,
                      },
                    }}
                  />
                )}
              />
            </Col>
            <Col xs={6} lg={3}>
              <Field
                name="EndDate"
                render={({ form, field }: FieldProps<FormValueProps>) => {
                  let prop = {}
                  const isContractEndDatePastAYear = moment(Instructor?.EndDateWithBerlitz).isSameOrBefore(
                    values.EndDate,
                    'date'
                  )

                  if (isContractEndDatePastAYear && Instructor?.EndDateWithBerlitz) {
                    prop = {
                      maxDate: moment(Instructor?.EndDateWithBerlitz).toDate(),
                    }
                  }

                  return (
                    <Datepicker
                      locale={enGB}
                      value={endDate}
                      selected={values.EndDate.clone().toDate()}
                      dateFormat="dd/MM/yyyy"
                      minDate={values.StartDate.toDate()}
                      {...prop}
                      label={`${intl.formatMessage({ id: 'End date' })}*`}
                      openToDate={values.EndDate.toDate()}
                      onSelect={(val, event) => {
                        const d = moment(val)

                        if (val && d.isValid() && event.type === 'click') {
                          form.setFieldValue(field.name, d)
                          setEndDate(d.format(DATE_FORMAT.DATEPICKER))
                        } else {
                          form.setFieldError(field.name, 'Required')
                        }
                      }}
                      onChangeRaw={(e) => {
                        debouceRequest(() => {
                          setEndDate(e.currentTarget.value)

                          if (AvailabilityForm.isDateValid(e.currentTarget.value)) {
                            const d = moment(e.currentTarget.value, DATE_FORMAT.DATEPICKER)
                            form.setFieldValue(field.name, d)
                            setEndDate(d.format(DATE_FORMAT.DATEPICKER))
                          } else {
                            form.setFieldError(field.name, 'Required')
                          }
                        })
                      }}
                      isClearable={false}
                      disabled={readOnly}
                      popperModifiers={{
                        preventOverflow: {
                          enabled: true,
                        },
                      }}
                    />
                  )
                }}
              />
            </Col>
          </Row>

          {!isTimeOff && (
            <FieldArray
              name="RepeatOn"
              render={(arrayHelpers: ArrayHelpers) => (
                <>
                  <Label>
                    <FormattedMessage id="Repeat on" />:
                  </Label>

                  <Row>
                    {RepeatOnOptions.map((day, index) => (
                      <Col xs={12} md={3} key={`repeat-on-${index}`}>
                        <Checkbox
                          key={`repeat-on-${index}`}
                          checked={values.RepeatOn.includes(day.value)}
                          name={`RepeatOn.${index}`}
                          label={intl.formatMessage({ id: day.label })}
                          value={`${day.value}`}
                          field={{
                            name: day.label,
                          }}
                          onChange={(e: any) => {
                            if (!readOnly) {
                              const { insert, remove } = arrayHelpers
                              if (e.target.checked) {
                                insert(index, day.value)
                              } else {
                                const valueIndex = values.RepeatOn.indexOf(day.value)
                                remove(valueIndex)
                              }
                            }
                          }}
                        />
                        <Spacer size="xxs" />
                      </Col>
                    ))}
                  </Row>
                </>
              )}
            />
          )}

          <Spacer size="xxxl" />

          <Footer>
            {!readOnly && (
              <>
                <Button type="button" onClick={onClose}>
                  <FormattedMessage id="Cancel" />
                </Button>
                {/* {deletable && !dirty && (
                  <Button type="button" onClick={onDelete}>
                    <FormattedMessage id="Delete" />
                  </Button>
                )} */}
                <Button
                  type="button"
                  onClick={() => onSave(values)}
                  disabled={!isValid || !dirty || !isEmpty(errors) || isInvalidDates()}
                >
                  <FormattedMessage id="Save" />
                </Button>
              </>
            )}
          </Footer>
        </form>
      )}
    </Formik>
  )
}

export default AvailabilityForm
